<template>

    <div class="h-full flex flex-col">
        <loader :loading="loading" />
        <div class="h-8 w-8 mr-2 rounded-full bg-body flex flex-col
            justify-center items-center" @click="goback()">
            <i class="mdi mdi-chevron-left text-aux text-xl"></i>
        </div>
        <div class="mt-6 flex justify-between items-center">
            <div class="flex items-center">
                <div class="text-font-gray">Filter by:</div>
                <div class="header-btn-gray ml-3 relative" v-for="(doc, index) in typeDocs"
                     :key="index" :class="{ [doc.class]: selectedExtensions === doc.value }"
                     @click="filterByExtensions(doc.value)" >
                    <i :class="'text-xl mdi mdi-' + doc.name"></i>
                </div>
            </div>

            <div class="relative w-84">
                <div class="relative flex-none w-84">
                    <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-8
                rounded bg-box-40 flex flex-row justify-start items-center px-2" />
                </div>
            </div>
        </div>
        <div v-if="alerts" class="flex-1 w-full min-h-0 flex flex-col justify-center
            items-center">
            <div class="h-full w-full mt-4 relative">
                <div v-if="search(filteredTipsAndDocs).length > 0" class="h-full mt-4 overflow-auto">
                    <div class="h-auto w-1/2 p-2  float-left overflow-y-auto"
                         v-for="(el, index) in search(filteredTipsAndDocs)"  :key="index">

                        <div v-if="el.file && el.file.length > 0" class="box flex items-center p-4 h-full">

                            <div class="flex-none rounded h-16 w-16 flex items-center justify-center text-center cursor-pointer"
                                 :class="getClassByExtension(el.file)" @click="openFile(el.file, 'file')">
                                <i class="mdi text-4xl" :class="getIconByExtension(el.file)"></i>
                            </div>

                            <div class="h-full flex-1 min-w-0">
                                <p class="text-lg text-font-light font-medium px-4 truncate-2 leading-normal">{{ el.title }}</p>
                            </div>

                            <div class="h-12 w-auto flex flex-col justify-between items-end text-sm text-font-gray self-start">
                                <p>{{ el.date | moment('MM/DD/YYYY') }}</p>
                                <i v-if="el.link && el.link != ''" class="mdi mdi-open-in-new text-font-gray cursor-pointer mt-auto" @click="go(el.link)"></i>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="!alerts && !loading" class="flex-1 min-h-0 w-full flex flex-col justify-center items-center">
            <span class="text-font-gray">No data</span>
        </div>
    </div>
</template>

<script>
    import { state } from '@/store';
    import pdf from 'vue-pdf';

    export default {
        components: {
            pdf
        },
        data() {
            return {
                alerts: null,
                totals: {
                    serious: 0,
                    medium: 0,
                    mild: 0
                },
                searchInput:'',
                typeDocs: this.$typeDocs,
                pdfSelected: false,
                selectedExtensions: [],
                fileOpen: false,
                loading: false
            }
        },
        methods: {
            go(link) { window.open(link, '_blank') },
            load() {
                this.loading = true;
                this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=TipAndDoc&sorts[date]=DESC&filters[active]=1', {params: state.globalParams})
                    .then(response => {
                        this.alerts = response.data
                        for (let index = 0; index < this.alerts.length; index++) {
                            switch (this.alerts[index].notification_type) {
                                case 'serious':
                                    this.totals.serious++
                                    break;
                                case 'medium':
                                    this.totals.medium++
                                    break;
                                case 'mild':
                                    this.totals.mild++
                                    break;
                            }
                        }
                        this.loading = false;
                    })

            },
            filterByExtensions(extensions) {
                if (extensions === this.selectedExtensions) this.selectedExtensions = []
                else this.selectedExtensions = extensions
            },
            getIconByExtension(file) {
                let result = ''
                result += this.$iconByExtension(file)
                return result
            },
            getClassByExtension(file) {
                let result = ''
                result += this.$colorByExtension(file)
                return result
            },
            openFile(file, type) { this.$openFile(file, type) },
            search(data){
                let parseArray = JSON.parse(JSON.stringify(data))
                return this.$search(parseArray, this.searchInput)
            },
            goback() { this.$router.go(-1) }
        },
        computed: {
            filteredTipsAndDocs() {
                if (this.selectedExtensions.length === 0) { return this.alerts }
                return this.alerts.filter(({ file }) => {
                    const pathProps = file.split('.');
                    const extension = pathProps[pathProps.length - 1];
                    return this.selectedExtensions.includes(extension);
                });
            },
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            },
            fileroute() { return process.env.VUE_APP_FILE }
        },
        watch: {
            params() { this.load() }
        },
        mounted() { 
            this.load() 

            setInterval(() => {
                this.load();
            }, state.refreshPersonalDashboard);    
        }
    }
</script>